import {ChartType} from '@/libs/enums/Charts'
import SALES_COMPARE_LAST_WEEK from '@/libs/charts/chartsConfigs/SALES_COMPARE_LAST_WEEK'
import SOLD_BY_PAYMENT_TYPE from '@/libs/charts/chartsConfigs/SOLD_BY_PAYMENT_TYPE'
import SOLD_BY_HOUR_IN_RANGE from '@/libs/charts/chartsConfigs/SOLD_BY_HOUR_IN_RANGE'
import SOLD_BY_PAYMENT_TYPE_IN_RANGE from '@/libs/charts/chartsConfigs/SOLD_BY_PAYMENT_TYPE_IN_RANGE'
import PRODUCT_SOLD_IN_RANGE from '@/libs/charts/chartsConfigs/PRODUCT_SOLD_IN_RANGE'
import SALES_BY_WEEK_IN_RANGE from '@/libs/charts/chartsConfigs/SALES_BY_WEEK_IN_RANGE'
import i18n from '@/i18n/i18n'

const localeObjects  = (locale) => {
    return {
            name: locale,
            options: {
                toolbar: {
                    exportToSVG: i18n.t('graphs.export_svg'),
                    exportToPNG: i18n.t('graphs.export_png'),
                    exportToCSV: i18n.t('graphs.export_csv'),
                    selection: i18n.t('graphs.selection'),
                    selectionZoom: i18n.t('graphs.selection_zoom'),
                    zoomIn: i18n.t('graphs.zoom_in'),
                    zoomOut: i18n.t('graphs.zoom_out'),
                    pan: i18n.t('graphs.pan'),
                    reset: i18n.t('graphs.reset')
                }
            }
        }
}

const chartConfigsMap = {
    [ChartType.SalesCompareLastWeek]: SALES_COMPARE_LAST_WEEK.getConfig,
    [ChartType.SoldByPaymentType]: SOLD_BY_PAYMENT_TYPE.getConfig,
    [ChartType.SoldByHourInRange]: SOLD_BY_HOUR_IN_RANGE.getConfig,
    [ChartType.SoldByPaymentTypeInRange]: SOLD_BY_PAYMENT_TYPE_IN_RANGE.getConfig,
    [ChartType.ProductSoldInRange]: PRODUCT_SOLD_IN_RANGE.getConfig,
    [ChartType.SalesByWeekInRange]: SALES_BY_WEEK_IN_RANGE.getConfig
}

function getConfig(chart, param, locale) {
    //const parsedData = param !== '' ? JSON.parse(param) : param
    const config = chartConfigsMap[chart](param)

    config.chart = {
        ...config.chart,
        locales: [localeObjects(locale)],
        defaultLocale: locale
    }

    return config
}

export default {
    chartConfigsMap,
    getConfig
}