<template>
    <b-modal :title="$t('general.set_location')" size="lg" v-model="modalActive" no-close-on-backdrop @close="modalActive = false">
        <template #default>
            <validation-observer ref="validation">
                <div>
                    <div>
                        <div class="row">
                            <div class="col-lg-8 col-12">
                                <b-form-group :label="$t('form_data.address')">
                                    <validation-provider #default="{ errors }" :name="$t('form_data.address')" rules="required">
                                        <b-form-input v-model="addObject.street"/>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                            <div class="col-lg-4 col-12">
                                <b-form-group :label="$t('config.business_units.house_number')">
                                    <div class="d-flex">
                                        <div class="pr-1">
                                            <validation-provider #default="{ errors }" :name="$t('config.business_units.house_number')" rules="required|min_value:0">
                                                <b-form-input type="number" placeholder="22" v-model.number="addObject.house_number"/>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </div>
                                        <div>
                                            <validation-provider #default="{ errors }" :name="$t('config.business_units.house_number_additional')">
                                                <b-form-input placeholder="B" v-model="addObject.house_number_additional"/>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-12">
                                <b-form-group :label="$t('form_data.post_code')">
                                    <validation-provider #default="{ errors }" :name="$t('form_data.post_code')" rules="required|min_value:0">
                                        <b-form-input type="number" v-model.number="addObject.postal_code"/>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                            <div class="col-lg-9 col-12">
                                <b-form-group :label="$t('form_data.city')">
                                    <validation-provider #default="{ errors }" :name="$t('form_data.city')" rules="required">
                                        <b-form-input v-model="addObject.city"/>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </div>
            </validation-observer>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="resetAddress">
                <feather-icon icon="XIcon" class="mr-50"/>
                <span class="align-middle">{{ $t('general.revert') }}</span>
            </b-button>
            <b-button variant="primary" @click="saveSettings">
                <feather-icon icon="SaveIcon" class="mr-50"/>
                <span class="align-middle">{{ $t('general.save') }}</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    import chartConfigs from '@/libs/charts/chartsConfigs/main'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, minValue } from '@validations'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver
        },

        data() {
            return {
                modalActive: false,
                chartConfigs,
                availableGraphsToday: [],
                availableGraphsPast: [],
                availableGraphs: [],
                required,
                minValue,
                addObject: {
                    street: '',
                    house_number: '',
                    house_number_additional: '',
                    postal_code: '',
                    city: ''
                }
            }
        },

        methods: {
            async open() {
                this.modalActive = true
                await this.loadData()
            },
            async saveSettings() {
                const valid = await this.$refs.validation.validate()
                if (valid) {
                    try {
                        await this.$http.post('/api/dashboard/v1/weather/address', this.addObject)
                        this.$printSuccess(this.$t('print.success.settings_saved'))
                    } catch (err) {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.general'))
                    } finally {
                        this.modalActive = false
                    }

                    this.$emit('save')
                }

            },

            async resetAddress() {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.reset_address'))
                    if (!confirmDelete) return
                    await this.$http.delete('/api/dashboard/v1/weather/address')
                    this.$printSuccess(this.$t('print.success.address_reset'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                } finally {
                    this.modalActive = false
                }

                this.$emit('save')
            },

            async loadData() {
                try {
                    const response =  await this.$http.get('/api/dashboard/v1/weather/address')
                    this.addObject = response.data !== 'no-address' ? response.data : {
                        street: '',
                        house_number: '',
                        house_number_additional: '',
                        postal_code: '',
                        city: ''
                    }
                } catch (error) {
                    Sentry.captureException(error)
                    this.$printError(this.$t('print.error.general'))
                }
            }
        }
    }
</script>