import { baseChartOption, mergeObjects } from '@/libs/charts/main'
import i18n from '@/i18n/i18n'

const donutChartOption =  () => {
    return {
        ...baseChartOption,
        chart: {
            type: 'donut'
        },
        plotOptions: {
            pie: {
                customScale: 0.85,
                donut: {
                    labels: {
                        show: true,
                        value:{
                            formatter: (item) => {
                                return `${(item / 1000000).toFixed(2)} €`
                            }
                        },
                        total: {
                            show: true,
                            showAlways: true,
                            label: i18n.t('reporting.issued_bills.total'),
                            formatter: (item) => {
                                const sum = item.config.series.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                                return `${(sum / 1000000).toFixed(2)} €`
                            }
                        }
                    }
                }
            }
        },
        noData: {
            text: i18n.t('general.no_data')
        },
        xaxis: {
            categories: []
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (item, data) => {
                    const sum = data.config.series.reduce((acc, currentValue) => acc + currentValue, 0)
                    const calcItem = item / 1000000
                    const percentage = (calcItem / (sum / 1000000)) * 100
                    return `${calcItem.toFixed(2)} € (${percentage.toFixed(2)} %)`
                }
            },
            fillSeriesColor: false
        },
        legend: {
            position: 'bottom'
        }
    }
}
function getConfig(rawConfig) {
    if (rawConfig !== null) {
        return mergeObjects(donutChartOption(), rawConfig)
    } else {
        return donutChartOption()
    }
}

export default {
    getConfig
}