
export const ChartType = {
    SalesCompareLastWeek: 'SALES_COMPARE_LAST_WEEK',
    SoldByPaymentType: 'SOLD_BY_PAYMENT_TYPE',
    SoldByHourInRange: 'SOLD_BY_HOUR_IN_RANGE',
    SoldByPaymentTypeInRange: 'SOLD_BY_PAYMENT_TYPE_IN_RANGE',
    ProductSoldInRange: 'PRODUCT_SOLD_IN_RANGE',
    SalesByWeekInRange: 'SALES_BY_WEEK_IN_RANGE'
}

export const TodayCharts = [
    ChartType.SalesCompareLastWeek,
    ChartType.SoldByPaymentType
]

export const PastCharts = [
    ChartType.SoldByHourInRange,
    ChartType.SoldByPaymentTypeInRange,
    ChartType.ProductSoldInRange,
    ChartType.SalesByWeekInRange
]