<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card
                no-body
                class="card-statistics"
            >
                <b-card-header>
                    <b-card-title>{{$t('graphs.statistics')}}</b-card-title>
                    <b-card-text class="font-small-2 mr-25 mb-0">
                        {{$t('graphs.last_updated')}}: {{lastUpdatedFormated}}
                    </b-card-text>
                </b-card-header>
                <b-card-body class="statistics-body">
                    <b-row>
                        <b-col
                            xl="3"
                            md="4"
                            sm="12"
                            class="my-1"
                            v-for="item in statisticsItems"
                            :key="item.icon"
                            :class="item.customClass"
                        >
                            <b-media no-body>
                                <b-media-aside

                                    class="mr-2"
                                >
                                    <b-avatar
                                        size="48"
                                        :variant="item.color"
                                    >
                                        <feather-icon
                                            v-if="item.iconType === 'feather'"
                                            size="24"
                                            :icon="item.icon"
                                        />
                                        <fa
                                            v-else-if="item.iconType === 'fa'"
                                            size="2x"
                                            :icon="item.icon" />
                                        <img alt="icon" v-else-if="item.iconType = 'custom'" :src="item.icon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">
                                        <span v-if="item.key === 'total_sold' || item.key === 'value_of_open_tables'">
                                            {{ $numberRounding(item.title, 2) }} €
                                        </span>
                                        <span v-else>
                                            {{ item.title }}
                                        </span>
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        {{ $t(item.subtitle) }}
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>
    import TableIcon from '@/assets/icons/TableIcon.svg'

    import {
        BAvatar,
        BCard,
        BCardBody,
        BCardHeader, BCardText,
        BCardTitle,
        BCol,
        BMedia,
        BMediaAside,
        BMediaBody,
        BRow,
        BOverlay
    } from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BCardText,
            BCol,
            BMediaBody,
            BCardHeader,
            BCardTitle,
            BAvatar,
            BRow,
            BMediaAside,
            BMedia,
            BCard,
            BCardBody,
            BOverlay
        },

        props: {
            selectedBusinessUnit: {
                type: String
            }
        },

        data() {
            return {
                statisticsItemsData: [],
                statisticsItems: [
                    {
                        iconType: 'feather',
                        icon: 'FileTextIcon',
                        color: 'light-primary',
                        title: '',
                        subtitle: 'graphs.num_of_bills',
                        customClass: 'mb-2 mb-xl-0',
                        key: 'total_bills'
                    },
                    {
                        iconType: 'feather',
                        icon: 'AwardIcon',
                        color: 'light-info',
                        title: '',
                        subtitle: 'graphs.sold',
                        customClass: 'mb-2 mb-xl-0',
                        key: 'total_sold'
                    },
                    {
                        iconType: 'custom',
                        icon: TableIcon,
                        color: 'light-danger',
                        title: '',
                        subtitle: 'graphs.open_tables',
                        customClass: 'mb-2 mb-sm-0',
                        key: 'open_tables'
                    },
                    {
                        iconType: 'fa',
                        icon: 'euro-sign',
                        color: 'light-success',
                        title: '',
                        subtitle: 'graphs.open_orders',
                        customClass: '',
                        key: 'value_of_open_tables'
                    }
                ],
                lastUpdated: new Date(),
                dataLoaded: false
            }
        },

        computed: {
            lastUpdatedFormated() {
                return this.dayjs(this.lastUpdated).format('HH:mm')
            }
        },

        methods: {
            async loadData() {
                this.dataLoaded = false
                try {
                    const response =  await this.$http.get('/api/dashboard/v1/dashboard/statistics')
                    this.statisticsItemsData = response.data ?? {}
                    this.statisticsItems = this.statisticsItems.map((item) => {
                        return {
                            ...item,
                            title: this.statisticsItemsData[item.key]
                        }
                    })
                    this.lastUpdated = new Date()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                } finally {
                    this.dataLoaded = true
                }
            }
        }
    }
</script>