import {baseChartOption, mergeObjects} from '@/libs/charts/main'
import dayjs from 'dayjs'
import i18n from '@/i18n/i18n'

function getDatesInWeek(year, weekNumber) {
    const startDate = new Date(year, 0, 1)
    const startDay = !startDate.getDay() ? 6 : startDate.getDay() - 1

    const daysToAdd = (weekNumber - 2) * 7
    startDate.setDate((8 - startDay) + daysToAdd)

    const datesInWeek = []

    for (let i = 0; i < 7; i++) {
        const currentDate = new Date(startDate)
        currentDate.setDate(startDate.getDate() + i)
        datesInWeek.push(dayjs(currentDate).format('DD. MM. YYYY'))
    }

    return datesInWeek
}

const heatMapChartOption = () => {
    return {
        ...baseChartOption,
        chart: {
            type: 'heatmap',
            toolbar:{
                show: true,
                offsetX: -37
            }
        },
        yaxis: {
            labels: {
                formatter: (item) => {
                    return item ? dayjs().day(parseInt(item)).format('dddd') : ''
                }
            }
        },
        tooltip: {
            enabled: true,
            fillSeriesColor: false,
            x: {
                formatter: (item) => {
                    const yearAndWeek = item.split('W')
                    const dates = getDatesInWeek(parseInt(yearAndWeek[0]), parseInt(yearAndWeek[1]))
                    return `${dates[0]} - ${dates[6]}`
                }
            },
            y: {
                formatter: (item, data) => {
                    const average = data.w.config.plotOptions.heatmap.calc_avg
                    return `${((item + average) / 1000000).toFixed(2)} €`
                },
                title: {
                    formatter: (item) => {
                        return item ? `${dayjs().day(parseInt(item)).format('dddd')}:` : ''
                    }
                }
            }
        },
        legend: {
            show: true,
            customLegendItems: [123],
            markers: {
                customHTML: '',
                width: 0,
                height: 0
            },
            formatter: (item, data) => {
                const average = data.w.config.plotOptions.heatmap.average
                return `${i18n.t('general.average')}: ${(average / 1000000).toFixed(2)} €`
            }
        },
        theme: {
            mode: 'light'
        },
        plotOptions: {
            heatmap: {
                enableShades: true,
                reverseNegativeShade: true,
                shadeIntensity: 0.2
            }
        },
        noData: {
            text: i18n.t('general.no_data')
        }
    }
}
function getConfig(rawConfig) {
    if (rawConfig !== null) {
        return mergeObjects(heatMapChartOption(), rawConfig)
    } else {
        return heatMapChartOption()
    }
}

export default {
    getConfig
}