<template>
    <div>
        <WeatherSettings ref="weatherSettings" @save="savedWeather" />
        <b-overlay :show="!dataLoaded">
            <b-card
                no-body
                class="card-statistics height-200"
            >
                <b-card-header>
                    <b-card-title>{{$t('graphs.weather')}}</b-card-title>
                    <b-card-text class="font-small-2 mr-25 mb-0">
                        {{$t('graphs.last_updated')}}: {{lastUpdatedFormated}}
                        <span class="ml-1 cursor-pointer" @click="openSettings"><feather-icon icon="SettingsIcon"/></span>
                    </b-card-text>
                </b-card-header>
                <b-card-body class="m-1">
                    <b-row v-if="weatherData && weatherData.conditions">
                        <b-col>
                            <b-media no-body>
                                <b-media-aside
                                    class="mr-2"
                                >
                                    <img alt="weather icon" :src="getImageSRC(weatherData.conditions.WeatherIcon)"/>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">
                                        {{ weatherData.conditions.Temperature.Metric.Value }} &deg;C / <span :class="isDark ? 'text-black-50' : 'text-white-50'" class="font-small-2">{{$t('graphs.real_feel')}}: {{weatherData.conditions.RealFeelTemperature.Metric.Value}} &deg;C</span>
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        {{ weatherData.conditions.WeatherText }}
                                    </b-card-text>
                                    <b-card-text class="font-small-2 mb-0">
                                        {{ weatherData.city_name }}
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                    </b-row>
                    <b-row v-else>
                        <b-col>
                            <b-card-text v-if="noResults || noAddress">
                                {{ $t('graphs.weather_no_data') }}
                            </b-card-text>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>
    import * as Sentry from '@sentry/vue'
    import {
        BCard,
        BCardBody,
        BCardHeader,
        BCardText,
        BCardTitle,
        BCol,
        BMedia,
        BMediaAside,
        BMediaBody,
        BRow,
        BOverlay
    } from 'bootstrap-vue'

    import WeatherSettings from '@/views/Dashboard/components/WeatherSettings.vue'

    export default {
        components: {
            BCardText,
            BCol,
            BMediaBody,
            BCardHeader,
            BCardTitle,
            BRow,
            BMediaAside,
            BMedia,
            BCard,
            BCardBody,
            BOverlay,
            WeatherSettings
        },

        data() {
            return {
                weatherData: {},
                lastUpdated: new Date(),
                dataLoaded: false,
                noResults: false,
                noAddress: false
            }
        },

        props: {
            businessUnit: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        computed: {
            lastUpdatedFormated() {
                return this.dayjs(this.lastUpdated).format('HH:mm')
            },
            isDark() {
                return !(this.$store.state.appConfig.layout.skin === 'dark')
            },
            currentLocale() {
                return this.$store.getters['user/current_locale']
            }
        },

        methods: {
            async savedWeather() {
                await this.loadData()
            },
            getImageSRC(img) {
                return `https://developer.accuweather.com/sites/default/files/${img <= 9 ? '0' : ''}${img}-s.png`
            },
            openSettings() {
                this.$refs.weatherSettings.open()
            },
            async loadData() {
                this.dataLoaded = false
                this.noResults = false
                this.noAddress = false
                try {
                    const response =  await this.$http.get('/api/dashboard/v1/weather')
                    this.weatherData = response.data
                    this.lastUpdated = new Date()
                } catch (error) {
                    Sentry.captureException(error)
                    if (error.response.data.error === 'no-address') {
                        this.noAddress = true
                    } else if (error.response.data.error === 'no-results') {
                        this.noResults = true
                    } else {
                        this.$printError(this.$t('print.error.general'))
                    }
                    this.weatherData = {}
                } finally {
                    this.dataLoaded = true
                }
            }
        },
        watch: {
            currentLocale() {
                this.loadData()
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>