<template>
    <b-overlay :show="!dataLoaded">
        <div>
            <!-- Izbira poslovnega prostora in gumbi -->
            <b-card v-if="dataExists && dashboardPermission">
                <div class="row">
                    <div class="col-lg-8 col-12 order-lg-0 order-1">
                        <v-select class="dashboard-select" v-if="allowedBusinessUnits.length > 1" :placeholder="$t('config.business_units.choose_business_area')" v-model="selected_business_unit" label="name" :options="allowedBusinessUnits" :clearable="false" :reduce="ele => ele.id" @option:selected="businessUnitChanged" />
                    </div>
                    <div class="col-lg col-12 my-lg-0 mb-lg-0 order-lg-1 order-0 my-1" >
                        <!--          {'stick-to-side': scrollFromTop,'float-right': !scrollFromTop}            -->
                        <b-button variant="primary" class="float-right" @click="refreshData">
                            <feather-icon icon="RefreshCwIcon"/>
                        </b-button>
                        <EditModal ref="editModal" v-if="dataExists" @save="saveSettings" />
                    </div>
                </div>
            </b-card>


            <!-- Ura, vreme in obvestila -->
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12" :class="{'col-lg-6': !dataExists}">
                    <Clock />
                </div>
                <div v-if="dataExists" class="col-lg-4 col-md-6 col-12">
                    <Weather ref="weather" :business-unit="selectedBusinessUnitFull" />
                </div>
                <div class="col-lg-4 col-12" :class="{'col-lg-6': !dataExists}">
                    <Notifications ref="notifications" />
                </div>
            </div>

            <!-- Statistika -->
            <div v-if="dashboardPermission">
                <div v-if="dataExists" class="row">
                    <div class="col-12 h-100">
                        <Statistics ref="statistics" v-if="dataLoaded" :selected-business-unit="selected_business_unit" />
                    </div>
                </div>

                <div v-if="dataExists" class="row">
                    <div class="col-12">
                        <Graph ref="graf" v-if="dataLoaded" :start-date="startDate" :end-date="endDate">
                            <div>
                                <div class="row">
                                    <div class="col-md-6 col-12 mb-md-0 mb-2">
                                        <label>{{$t('general.start_date')}}</label>
                                        <date-picker :locale="i18n().locale" :first-day-of-week="2" :max-date='endDate' disabled-dates is24hr v-model="startDate" :masks="{ input: ['DD. MM. YYYY'], data: ['DD. MM. YYYY'] }">
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <input class="form-control"
                                                       :value="inputValue"
                                                       v-on="inputEvents"
                                                />
                                            </template>
                                        </date-picker>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <label>{{$t('general.end_date')}}</label>
                                        <date-picker :locale="i18n().locale" :first-day-of-week="2" :min-date='startDate' disabled-dates is24hr v-model="endDate" :masks="{ input: ['DD. MM. YYYY'], data: ['DD. MM. YYYY'] }">
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <input class="form-control"
                                                       :value="inputValue"
                                                       v-on="inputEvents"
                                                />
                                            </template>
                                        </date-picker>
                                    </div>
                                </div>
                            </div>
                        </Graph>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import * as Sentry from '@sentry/vue'
    import Notifications from '@/views/Dashboard/components/Notifications.vue'
    require('dayjs/locale/sl')
    import EditModal from '@/views/Dashboard/components/EditModal.vue'
    import vSelect from 'vue-select'
    import Clock from '@/views/Dashboard/components/Clock.vue'
    import Weather from '@/views/Dashboard/components/Weather.vue'
    import Statistics from '@/views/Dashboard/components/Statistics.vue'
    import Graph from '@/views/Dashboard/components/Graph.vue'
    import {DatePicker} from 'v-calendar'
    import i18n from '@/i18n/i18n'

    import {
        BCard,
        BButton,
        BOverlay
    } from 'bootstrap-vue'


    export default {
        components: {
            EditModal,
            Clock,
            Weather,
            Statistics,
            Graph,
            Notifications,
            BButton,
            BCard,
            BOverlay,
            vSelect,
            DatePicker
        },
        watch: {
            async userData(userData) {
                if (!userData || !userData.client_id) {
                    return
                }

                await this.mainLoad()

                if (this.dataExists && this.$refs.editModal) {
                    await this.$refs.editModal.loadData()
                }
            },
            currentLocale() {
                this.dataLoaded = false
                this.mainLoad()
            }
        },
        computed: {
            currentLocale() {
                return this.$store.getters['user/current_locale']
            },
            userData() {
                return this.$store.getters['user/userData']
            },
            selectedBusinessUnitFull() {
                return this.allowedBusinessUnits.find(x => x.id === this.selected_business_unit)
            },
            dashboardPermission() {
                return this.$hasPermission(this.$permissions.Dashboard)
            }
        },
        data() {
            return {
                lastUpdated: new Date(),
                dataLoaded: false,
                startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                endDate: new Date(),
                selected_business_unit: null,
                allowedBusinessUnits: [],
                dataExists: false,
                scroll: 0
            }
        },

        methods: {
            i18n() {
                return i18n
            },
            async saveSettings() {
                await this.refreshData()
            },
            async loadBusinessUnits() {
                this.dataLoaded = false
                try {
                    const response =  await this.$http.get('/api/client/v1/business_units')
                    this.allowedBusinessUnits = response.data ?? []
                    this.lastUpdated = new Date()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                } finally {
                    this.dataLoaded = true
                }
            },
            async refreshData() {
                await this.$nextTick()

                if (this.dataExists && this.dataLoaded) {

                    if (this.$refs.statistics) {
                        this.$refs.statistics.loadData()
                    }
                    if (this.$refs.graf) {
                        this.$refs.graf.loadData(true)
                    }
                    if (this.$refs.weather) {
                        this.$refs.weather.loadData()
                    }
                    if (this.$refs.notifications) {
                        this.$refs.notifications.loadData()
                    }
                    
                }

            },
            async businessUnitChanged() {
                try {
                    const data = {
                        'id': this.selected_business_unit
                    }
                    await this.$http.post('/api/dashboard/v1/business_unit', data)
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                }
                await this.refreshData()
            },
            async getBusinessUnit() {
                try {
                    const response =  await this.$http.get('/api/dashboard/v1/business_unit')
                    this.selected_business_unit = response.data
                    this.dataExists = this.selected_business_unit !== this.$NilObjectId
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                    this.dataExists = false
                }
            },
            async mainLoad() {
                await this.getBusinessUnit()
                await this.loadBusinessUnits()

                if (this.dashboardPermission) {
                    await this.refreshData()
                } else {
                    this.dataLoaded = true
                }


            }

        },
        async mounted() {
            await this.mainLoad()
            window.onscroll = () => {
                this.scroll = window.scrollY
            }
        }
    }
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select';

.dark-layout .dashboard-select .vs__actions svg {
    fill: #b4b7bd;
}

.stick-to-side {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 100;
    padding: 15px;
    aspect-ratio: 1;
    border-radius: 8px 0 0 8px;
}
</style>