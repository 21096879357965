var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.$t('general.set_location'),"size":"lg","no-close-on-backdrop":""},on:{"close":function($event){_vm.modalActive = false}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('validation-observer',{ref:"validation"},[_c('div',[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 col-12"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.address')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addObject.street),callback:function ($$v) {_vm.$set(_vm.addObject, "street", $$v)},expression:"addObject.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-lg-4 col-12"},[_c('b-form-group',{attrs:{"label":_vm.$t('config.business_units.house_number')}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pr-1"},[_c('validation-provider',{attrs:{"name":_vm.$t('config.business_units.house_number'),"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"22"},model:{value:(_vm.addObject.house_number),callback:function ($$v) {_vm.$set(_vm.addObject, "house_number", _vm._n($$v))},expression:"addObject.house_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('config.business_units.house_number_additional')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"B"},model:{value:(_vm.addObject.house_number_additional),callback:function ($$v) {_vm.$set(_vm.addObject, "house_number_additional", $$v)},expression:"addObject.house_number_additional"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-12"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.post_code')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.post_code'),"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.addObject.postal_code),callback:function ($$v) {_vm.$set(_vm.addObject, "postal_code", _vm._n($$v))},expression:"addObject.postal_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-lg-9 col-12"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.city')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.city'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addObject.city),callback:function ($$v) {_vm.$set(_vm.addObject, "city", $$v)},expression:"addObject.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])])])])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.resetAddress}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.revert')))])],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveSettings}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.save')))])],1)]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }