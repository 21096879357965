<template>
    <div>
        <b-card class="height-200">
            <b-card-body class="text-center p-0 pt-1">
                <span class="clock">{{currentTimeFormated}}</span>
                <br/>
                <span :class="isDark ? 'text-black-50' : 'text-white-50'">{{dayjs(new Date()).format('dddd, D. M. YYYY')}}</span>
            </b-card-body>
        </b-card>
    </div>
</template>
<script>
    require('dayjs/locale/sl')
    import {BCard, BCardBody} from 'bootstrap-vue'

    export default {
        components: {
            BCard,
            BCardBody
        },

        data() {
            return {
                currentTime: null,
                timeInterval: null
            }
        },

        computed: {
            currentTimeFormated() {
                return this.dayjs(this.currentTime).format('HH:mm')
            },
            isDark() {
                return !(this.$store.state.appConfig.layout.skin === 'dark')
            }
        },

        methods: {
            setTime() {
                this.currentTime = new Date().getTime()
            }
        },

        async mounted() {
            this.setTime()
            this.timeInterval = setInterval(this.setTime, 1000)
        },
        beforeDestroy() {
            clearInterval(this.timeInterval)
        }
    }
</script>

<style scoped>
.clock{
    font-size: 5em;
    text-align: center;
}
</style>